import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { UserService } from './user.service';
import { GenericstorageService } from './genericstorage.service';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  pendingRequest: any = window.localStorage.getItem('pendingRequest') ? JSON.parse(window.localStorage.getItem('pendingRequest')) : null;

  constructor(
    public AppCMS: AppcmsService,
    public userService: UserService,
    public storage: GenericstorageService,
  ) {

  }

  approve(request: any) {
    delete request.user;
    
    return new Promise((resolve, reject) => {
      this.storage
      .delete(request.uid)
      .then(deleteResponse => {
        this.userService.getByUid(request.parent)
        .then((user: any) => {
          user.classifications = user.classifications || {};
          user.classifications.stores = request.inAppLink;

          this.userService.update(user).then(resolve).catch(reject);
        })
        .catch(reject);
      })
      .catch(reject);
    });
  }

  create(store: any = {}) {
    let options: any = {
      store: store,
      user: this.userService.getApiCredentials(),
    };
    return this.AppCMS.loadPluginData('stores', options, ['create']);
  }

  decline(request: any) {
    delete request.user;
    
    return new Promise((resolve, reject) => {
      request.active = false;
      this.storage.update(request)
      .then(resolve)
      .catch(reject);
    });
  }

  get(options: any = {}) {
    options.user = this.userService.getApiCredentials();
    return this.AppCMS.loadPluginData('stores', options);
  }

  getAll() {
    return this.AppCMS.loadPluginData('stores', { user: this.userService.getApiCredentials() });
  }

  getByProfile(profile: any) {
    return this.getCurrent(profile);
  }

  getByUid(uid: number) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('stores', {
        user: this.userService.getApiCredentials()
      }, [uid])
      .then((store: any) => {
        resolve(this.getFullStore(store));
      })
      .catch(reject);
    });
  }

  getCurrent(user = null) {
    user = user || this.userService.getUser();
    return user && user.classifications && user.classifications.stores ?
      user.classifications.stores : 
      null;
  }

  getFullStore(store: any) {
    if(store && store.photo && store.photo.length) {
      store.photo = store.photo.replace('http://', 'https://');
    }
    return store;
  }

  getPendingRequest() {
    return this.pendingRequest;
  }

  getRequests(storeId: number) {
    return new Promise((resolve, reject) => {
      this.storage.get({
        active: true,
        inAppLink: storeId,
        type: 'kegl:request',
      })
      .then((requests: any) => {
        let requestsWithParent = requests.filter(request => {
          return request.parent;
        });
        if(requestsWithParent.length) {
          requestsWithParent.forEach((request, iRequest) => {
            this.userService.getByUid(request.parent)
            .then(user => {
              requestsWithParent[iRequest].user = user;

              if(iRequest === requestsWithParent.length-1) {
                resolve(requestsWithParent);
              }
            })
            .catch(reject);
          });
        } else {
          resolve(requests);
        }
      })
      .catch(reject);
    });
  }

  request(storeId: any) {
    return new Promise((resolve, reject) => {
      let requestOptions: any = {
        active: true,
        inAppLink: storeId,
        parent: this.userService.getUser().uid,
        type: 'kegl:request',
      };
      this.storage.create(requestOptions)
      .then((response: any) => {
        this.setPendingRequest(response.item);
        resolve(response);
      })
      .catch(reject);
    });
  }

  setPendingRequest(request: any) {
    this.pendingRequest = request;
    if(request) {
      window.localStorage.setItem('pendingRequest', JSON.stringify(this.pendingRequest));
    } else {
      window.localStorage.removeItem('pendingRequest');
    }
  }

  update(store: any) {
    return this.AppCMS.loadPluginData('stores', {
      store: store,
      user: this.userService.getApiCredentials(),
    }, ['update']);
  }

}
