import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventsService } from '../events.service';
import { PushnotificationsService } from '../pushnotifications.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.page.html',
  styleUrls: ['./message.page.scss'],
})
export class MessagePage implements OnInit {

  message: message = {
    content: '',
  };

  constructor(
    public events: EventsService,
    public modalCtrl: ModalController,
    public push: PushnotificationsService,
  ) {

  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
  }

  send() {

  }

}
