import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  current: any = window.localStorage.getItem('currentProject') ? JSON.parse(window.localStorage.getItem('currentProject')) : null;

  constructor(
    public AppCMS: AppcmsService,
    public userService: UserService,
  ) {

  }

  create(project: any) {
    return new Promise((resolve, reject) => {

      this.AppCMS.loadPluginData('projects', {
        project: project,
        user: this.userService.getApiCredentials(),
      }, ['create'])
        .then((response: any) => {
          if (response.success) {
            resolve(response);
          } else {
            reject(response.message || null);
          }
        })
        .catch(reject);
    });
  }

  getAll() {
    let user = this.userService.getUser(),
      data: any = { user: this.userService.getApiCredentials() };

    if (user) {
      if (!user.classifications || user.classifications.type !== 'Admin') {
        data.filter = { customer: user.uid };
      }
    }

    return this.AppCMS.loadPluginData('projects', data);
  }

  getByUid(uid: number) {
    return this.AppCMS.loadPluginData('projects', { user: this.userService.getApiCredentials() }, [uid]);
  }

  getByCategory(categoryId: number) {
    return this.AppCMS.loadPluginData('projects', {
      category: categoryId,
      user: this.userService.getApiCredentials()
    });
  }

  getCurrent() {
    return this.current;
  }

  setCurrent(project: any) {
    this.current = project;
    window.localStorage.setItem('currentProject', JSON.stringify(this.current));
  }

}
