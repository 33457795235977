import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { AppcmsService } from './appcms.service';
import { CacheService } from './cache.service';
import { EventsService } from './events.service';
import { PrinterService } from "./printer.service";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    public AppCMS: AppcmsService,
    public cache: CacheService,
    public events: EventsService,
    public userService: UserService,
    public printer: PrinterService,
  ) {

  }
  selectprinter(){
   this.printer.selectprinter()
  }
  getSetting(key: string, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.getSettings(null, blForceRefresh)
      .then((settings: any) => {
        resolve(settings[key]);
      })
      .catch((error: any) => {
        if(error && error.toLowerCase().indexOf('error') !== -1) {
          reject(error);
        } else {
          resolve(error); // resolve(), because empty setting is okay here
          // @todo validate error
        }
      });
    });
  }

  getSettings(userId: number = null, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      userId = userId || this.userService.getUser().uid;
      this.userService.getByUid(userId, blForceRefresh)
      .then((user: user) => {
        resolve(user && user.classifications && user.classifications.settings ? user.classifications.settings : {});
      })
      .catch(reject);
    });
  }

  setSettings(settings: any, userId: number = null) {
    let user = this.userService.getUser();
    userId = userId || user.uid;
    return this.cache.set('user_settings_' + userId, settings);
  }

  async showUpdatedToast() {
    this.events.publish('toast', {
      icon: 'checkmark-outline',
      message: 'settings_updated',
      color: 'primary',
    });
  }

  updateSettings(settings: any) {
    return new Promise((resolve, reject) => {
      let user = this.userService.getUser() || {};
      if(!user.uid) {
        reject('error_missing_user_uid');
      } else {
        this.getSettings(user.uid, true)
        .then((_settings: any) => {
          settings = Object.assign(_settings, settings);
          user.classifications.settings = settings;
          this.userService.setUser(user, true).then(resolve).catch(reject);
        })
        .catch(reject);
      }
    });
  }

  updateSetting(key: string, value: any) {
    let settings = {};
    settings[key] = value;

    console.log('updateSetting', key, value);
    
    /*
    let user = this.userService.getUser() || {};
    this.log.add({
      class: 'updated_settings',
      group: 'updated_settings_' + user.uid,
      description: (user.nickname || user.firstname) + ' hat die Einstellung "' + key + '" aktualisiert',
    })
    .catch((error: any) => {
      console.warn('log error:', error);
    });
    */

    return this.updateSettings(settings);
  }

}
