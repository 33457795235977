import { Component, OnInit } from '@angular/core';
import { EventsService } from '../events.service';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.page.html',
  styleUrls: ['./create-product.page.scss'],
})
export class CreateProductPage implements OnInit {

  modalCtrl: any;
  
  constructor(
    public events: EventsService,
    public navParams: NavParams,
  ) {
    this.modalCtrl = this.navParams.get('modalCtrl');
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
  }

}
