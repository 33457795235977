import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { WoocommerceextendService } from '../woocommerceextend.service';
import { EventsService } from '../events.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.page.html',
  styleUrls: ['./product.page.scss'],
})
export class ProductPage implements OnInit {

  modalCtrl: any;

  product: any;

  view: any = {
    attribute_taxonomy: 'custom',
    segment: 'general',
  };

  constructor(
    public events: EventsService,
    public navParams: NavParams,
    public woocommerce: WoocommerceextendService,
  ) {
    this.modalCtrl = this.navParams.get('modalCtrl');
    this.product = this.navParams.get('product');
    this.product.regular_price = this.product.regular_price || this.product.price;
    //console.log('this.product', this.product);

  }

  addDiscountDateRange() {
    
  }

  addTaxonomy() {

  }

  create() {
    this.woocommerce.createProduct(this.product)
    .then((response: any) => {
      //console.log('response', response);
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
  }

  update() {
    this.woocommerce.updateProduct(this.product)
    .then((response: any) => {
      //console.log('response', response);
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

}
